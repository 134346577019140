let pxQueries = {
  '--mobile-up': '(min-width: 416px)',
  '--tablet-up': '(min-width: 640px)',
  '--tablet-lg-up': '(min-width: 1024px)',
  '--laptop-up': '(min-width: 1152px)',
  '--desktop-up': '(min-width: 1600px)',
  '--ultrawide': '(min-width: 2000px)',
};

let px_breakpoints = {
  '--mobile-up': 416,
  '--tablet-up': 640,
  '--tablet-lg-up': 1024,
  '--laptop-up': 1152,
  '--desktop-up': 1460,
  '--ultrawide': 2000,
};

export default { pxQueries, px_breakpoints };
