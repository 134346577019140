<template>
  <div v-if="props.products" class="product__slider">
    <splide :options="options" ref="productSlider">
      <template v-for="product in props.products" :key="product.id">
        <splide-slide>
          <ProductItem
            :product="product"
            :customer="customer"
            :source="source"
          />
        </splide-slide>
      </template>
      <template v-slot:controls>
        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev"></button>
          <button class="splide__arrow splide__arrow--next"></button>
        </div>
      </template>
    </splide>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from 'vue';
import '@splidejs/vue-splide/css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import ProductItem from './ProductItem.vue';
import IconChevronRight from './icons/IconChevronRight.vue';

const props = defineProps({
  options: {
    type: Object,
    required: true,
    default: () => {},
  },
  products: {
    type: Array,
    required: false,
  },
  customer: {
    type: Object,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const productSlider = ref(null);

</script>
